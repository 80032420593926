<template>
  <div class="bg">
    <section class="banner">
      <img class="bannerImg" src="../../assets/img/about/about-banner.jpg" alt="" width="100%">
      <div class="banner-block">
        <div class="title">
          <h1 class="banner-title hidden-xs-only">关于我们</h1>
          <svg-icon class="banner-line-img hidden-xs-only" svg-name="title-white"></svg-icon>

          <h2
            class="banner-sutitle hidden-xs-only"
          >以国家产业政策为导向，以市场为驱动，以服务开发者企业为主体，搭建搭载智能终端的软件生态平台和技术标准。促进联盟成员企业的软件生态研发、设计、集成、服务等水平，探索和构建智能终端产业的下一代软件生态，提升我国智能终端软件产业的竞争力，促进技术进步、提高生产效率，支持新技术、新产业、新业态、新模式的加快发展。</h2>
        </div>
      </div>
    </section>
    <el-row>
      <el-col :xs="{span:24,offset:0}" :span="18" :offset="3">
        <section class="text-center">
          <h3 class="section-title">移动智能终端生态联盟</h3>
          <h4 class="section-subtitle">Intelligent Terminal Golden Seal Alliance</h4>
          <svg-icon class="section-img hidden-xs-only" svg-name="title-white"></svg-icon>
        </section>
      </el-col>
    </el-row>
    <section class="content-block">
      <el-row>
        <el-col :xs="{span:24,offset:0}" :span="24">
          <div class="title-bg">
            <p>本联盟是在自愿、平等、互利合作的基础上，由中国智能终端厂商（以下顺序按公司名称首字拼音顺序排列）阿里巴巴网络技术有限公司（以下简称“阿里”）、百度在线网络技术（北京）有限公司（以下简称“百度”）、OPPO广东移动通信有限公司（以下简称“OPPO”）、深圳市腾讯计算机系统有限公司（以下简称“腾讯”）、维沃移动通信有限公司（以下简称“vivo”）、小米科技有限责任公司（以下简称“小米”）自愿联合发起成立的开放性、非营利性的社会组织。</p>
            <p>
              本联盟旨在以国家产业政策为导向，以市场为驱动，以服务开发者企业为主体，搭建搭载智能终端的软件生态平台和技术标准。促进联盟成员企业的软件生态研发、设计、集成、服务等水平，探索和构建智能终端产业的下一代软件生态，提升我国智能终端软件产业的竞争力，促进技术进步、提高生产效率，支持新技术、新产业、新业态、新模式的加快发展。
              本联盟立足于搭建智能终端设备的软件生态平台和技术标准，服务上下游生态企业，支撑政府决策，推进智能终端软件产业发展。
            </p>
            <p>联盟任务是着力聚集产业生态各方力量，联合开展智能终端软件的技术标准生态研究，共同探索软件在联盟技术标准生态下的新技术、新模式和新机制，推进技术、产业与应用研发，开展试点示范，广泛开展产业合作，形成智能终端软件技术标准生态的服务平台。</p>
          </div>
        </el-col>
      </el-row>
    </section>

    <section class="row-block3">
      <el-row>
        <el-col :xs="{span:24,offset:0}" :span="18" :offset="3">
          <section class="text-center">
            <h3 class="section-title">联盟成员</h3>
            <h4 class="section-subtitle">Alliance members</h4>
            <svg-icon class="section-img hidden-xs-only" svg-name="title-black"></svg-icon>
          </section>
        </el-col>
      </el-row>

      <section class="content-block memberBox">
        <el-row>
          <el-col :xs="{span:24,offset:0}" :span="6">
            <section class="section-block">
              <div class="title-block">
                <svg-icon class="icon-img1" svg-name="理事会成员"></svg-icon>
                <div class="title">
                  <p class="title1">理事长单位</p>
                  <p class="title2">Chairman of the Council</p>
                </div>
              </div>
              <p class="sub-title" >即联盟发起单位：阿里、百度、OPPO、腾讯、vivo、小米。理事会成员将决定联盟的战略方向、重大事项，制定联盟官方材料，发起或参与联盟下设的工作组或项目，主导联盟技术标准制定等。</p>
            </section>
          </el-col>
          <el-col :xs="{span:24,offset:0}" :span="6">
            <section class="section-block">
              <div class="title-block">
                <svg-icon class="icon-img1" svg-name="战略生态合作伙伴"></svg-icon>
                <div class="title">
                  <p class="title1">理事单位</p>
                  <p class="title2">Members of the Council</p>
                </div>
              </div>
              <p class="sub-title">承认本章程的会员成员，提出申请，经理事长单位全体投票通过后，即可成为理事单位。理事单位享有下列权利：参与讨论联盟的战略方向、重大事项；联盟官网/官方材料露出；参与联盟下设的工作组或项目；可参与联盟技术标准制定；联盟公开资源共享。</p>
            </section>
          </el-col>
          <el-col :xs="{span:24,offset:0}" :span="6">
            <section class="section-block">
              <div class="title-block">
                <svg-icon class="icon-img1" svg-name="注册籍会员"></svg-icon>
                <div class="title">
                  <p class="title1">生态单位</p>
                  <p class="title2">Ecological members</p>
                </div>
              </div>
              <p class="sub-title">认可联盟发布的技术标准，完成联盟官网注册，满足会员必备条件，并且完成会员申请，即可成为生态单位。生态单位享有下列权利：可在联盟官网展示LOGO；可参与联盟下设的工作组或项目；可参与联盟技术标准制定；联盟公开资源共享。</p>
            </section>
          </el-col>
          <el-col :xs="{span:24,offset:0}" :span="6">
            <section class="section-block">
              <div class="title-block">
                <svg class="icon-img1" aria-hidden="true"><use xlink:href="#iconhezuohuoban1" /></svg>
                <div class="title">
                  <p class="title1">合作伙伴</p>
                  <p class="title2">Partners</p>
                </div>
              </div>
              <p class="sub-title" >认可联盟发布的技术标准，完成联盟官网注册，满足会员必备条件，并且完成会员申请，即可成为合作伙伴。合作伙伴享有下列权利：可在联盟官网展示LOGO；可参与联盟下设的工作组或项目；可参与联盟技术标准制定。</p>
            </section>
          </el-col>
        </el-row>
      </section>
    </section>

    <section class="content-block">
      <el-row>
        <el-col :xs="{span:24,offset:0}" :span="24">
          <a class="zhangcheng-bg" href="/home/constitution" target="_blank">
            <div class="constitutionIcon-box">
              <svg-icon class="constitution-icon " svg-name="章程"></svg-icon>
            </div>
            <p class="zhangcheng-title" >移动智能终端生态联盟章程</p>
          </a>
        </el-col>
      </el-row>
    </section>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title:"ITGSA | 移动智能终端生态联盟 | 关于我们",
    meta: [
      {
        name: 'keywords',
        content: 'ITGSA,理事会成员,战略生态合作伙伴,注册籍会员，移动智能终端生态联盟章程'
      },
      {
        name: 'description',
        content: '以国家产业政策为导向，以市场为驱动，以服务开发者企业为主体，搭建搭载智能终端的软件生态平台和技术标准。促进联盟成员企业的软件生态研发、设计、集成、服务等水平，探索和构建智能终端产业的下一代软件生态，提升我国智能终端软件产业的竞争力，促进技术进步、提高生产效率，支持新技术、新产业、新业态、新模式的加快发展。'
      },
    ]
  },
  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}

.bg {
  width: 100%;
  height: 100%;
  background-color: white;
}

.banner {
  position: relative;
  width: 100%;
  min-height: 300px;
  background: #fff;
  .bannerImg{
    width: 100%;
    min-height: 300px;
  }
}

@media screen and (max-width: 767px) {
  .banner {
    background: url(../../assets/img/about/banner-m.jpg) bottom no-repeat;
    background-size: cover;
    .bannerImg{
      display: none;
    }
  }
}


.banner-block {
  position: absolute;
  height: 100%;
  transform: translateX(-50%);
  bottom: 0;
  left: 50%;
  width: 946px;

  .title {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 68%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .banner-block {
    width: 100%;
  }
}

.banner-line-img {
  width: 382px;
  height: 27px;
}

.banner-title {
  font-size: 48px;
  line-height: 100px;
  text-align: center;
  font-weight: 900;
  color: #f2e9c1;
}

.banner-sutitle {
  margin-top: 20px;
  font-size: 14px;
  text-align: center;
  font-weight: 300;
  color: rgba(242, 233, 193, 1);
  line-height: 20px;
}

.section-title {
  margin-top: 80px;
  font-size: 36px;
  height: 50px;
  font-weight: bold;
  color: #c3ba93;
}

.section-img {
  width: 882px;
  height: 37px;
}

.section-subtitle {
  height: 35px;
  font-size: 18px;
  font-weight: 300;
  color: rgba(153, 153, 153, 1);
  line-height: 25px;
}

.title-bg {
  margin: 0 auto;
  width: 1226px;
  height: 486px;
  background: url(../../assets/img/about/title-bg.png) top no-repeat;
  background-size: cover;
  text-indent: 26px;
  padding: 95px 120px 60px 200px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 300;
  color: rgba(153, 153, 153, 1);
  line-height: 26px;
  p {
    margin-bottom: 15px;
    text-align: justify;
  }
}

@media screen and (max-width: 767px) {
  .title-bg {
    width: 100%;
    height: auto;
    padding: 10%;

    background: rgba(31, 29, 34, 1);
  }
}

.row-block3 {
  margin-top: 40px;
  width: 100%;
  height: 560px;
}

@media screen and (max-width: 767px) {
  .row-block3 {
    height: 1200px;
  }
}

.section-block {
  margin: 10px auto;
  width: 358px;
  height: 300px;
  // background: rgba(255, 255, 255, 1);
}

.memberBox{
width: 1600px;
  .title-block {
    width: 100%;
    height: 116px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 8px 8px 0 0;
    .svg-icon{
      margin-left: -40px;
    }
    .icon-img1 {
      width: 50px;
      height: 50px;
    }

    .title {
      margin-left: 20px;
      font-weight: bold;
      .title1 {
        font-size: 23px;
      }

      .title2 {
        font-size: 18px;
        margin-top: 5px;
      }
    }
  }
  .sub-title {
    background: rgba(255, 255, 255, 1);
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 300;
    color: rgba(102, 102, 102, 1);
    line-height: 24px;
    text-align: justify;
    height: 160px;
  }
}

.zhangcheng-bg {
  display: block;
  margin:auto ;
  margin-top:100px ;
  margin-bottom:92px ;
  width: 1240px;
  height: 244px;
  padding-top: 50px;
  background: url(../../assets/img/about/zhangcheng-bg.png) top no-repeat;
  background-size: cover;
  transition: all 0.3s;
  .constitution-icon{
    display: block;
    margin: auto;
    margin-top: -30px;
    width: 150px;
    height: 150px;
    // stroke:  #bcb38e;
    // filter: drop-shadow(#bcb38e 0px 0);
  }
  .zhangcheng-title {
    display: block;
    margin: auto;
    height: 38px;
    border-radius:18px;
    line-height: 38px;
    color: #000;
    color: #c3ba93;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 2px;
    text-decoration: none;
    font-weight: bold;
  }
  &:hover  {
    transform: scale(1.1,1.1);
    cursor: pointer;
  }

}

@media screen and (max-width: 767px) {
  .zhangcheng-bg {
    margin: 50px auto 0px;
    width: 100%;
    height: 169px;
    background: rgba(31, 29, 34, 1);
  }
}


.contact-block {
  width: 230px;
  margin: 0 auto;
}

.contact-title {
  width: 145px;
  height: 28px;
  font-size: 18px;
  font-weight: bold;
  color: rgba(40, 38, 44, 1);
}

.contact-phone {
  margin-top: 8px;
  width: 195px;
  height: 30px;

  img {
    margin: 5px 5px 0 0;
  }
  span {
    font-size: 14px;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    // line-height: 30px;
  }
}

.contact-email {
  margin-bottom: 60px;
  margin-top: 8px;
  width: 225px;
  height: 30px;
  display: flex;
  align-items: baseline;
  // img {
  //   margin: 5px 5px 0 0;
  // }
  span {
    font-size: 14px;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    // line-height: 30px;
  }
}

.email-img {
  margin: 0 5px 0 0;
  width: 12px;
  height: 12px;
}

.contact-line {
  width: 20px;
  height: 2px;
  background: rgba(40, 38, 44, 1);
}
</style>
